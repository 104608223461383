export const primary = "#f5ca01";
export const secondary = "#B1AF05";
export const PATHNAME = window.location.href;
export const YTEmbed = "https://www.youtube-nocookie.com/embed/";
export const isMobile = window.innerWidth <= 768;

export const SPOTIFY_CLIENT_ID = "1c6bc79302f446279a92c917b127bd09"
export const SPOTIFY_CLIENT_SECRET = "e1f9435d382844a3b4c6a2232649eb25"
// export const SPOTIFY_REDIRECT_URI = "http://localhost:3000/callback"
export const SPOTIFY_REDIRECT_URI = "https://kenya-vaun-playlist.700sandbox.com/callback"
export const SPOTIFY_AUTH_ENDPOINT = "https://accounts.spotify.com/authorize"
export const SPOTIFY_RESPONSE_TYPE = "token"
