import React, { useEffect, useRef, useState } from "react";
import { preloadVideo } from "@remotion/preload";
import { RoutePattern } from "../../../routes/RoutePattern";
import VIDEO1 from "../../../assets/videos/Hero Desktop_No Text V3.mp4";
import VIDEO2 from "../../../assets/videos/Hero Mobile 9x20_No Text V3.1.mp4";
import IMG1 from "../../../assets/images/Hero Desktop_No Text V3.png";
import IMG2 from "../../../assets/images/Hero Mobile 9x20_No Text V3.png";
import styled from "styled-components";
import { SPOTIFY_AUTH_ENDPOINT, SPOTIFY_CLIENT_ID, SPOTIFY_REDIRECT_URI, SPOTIFY_RESPONSE_TYPE, isMobile } from "../../../constants";
import Typewriter from "typewriter-effect";
import { data } from "./playlist";

// const unpreload = preloadVideo(
//   VIDEO2
// );

const VideoPlayer = styled.video`
`;

const VideoContainer = styled.div`
  @media (max-width: 820px) {
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    position: absolute;
  }
`;

const PlaylistContainer = styled.div`
  @media (max-width: 415px) {
    padding-top: 2.5rem;

    & .right-col {
      padding-left: 0.8rem;
    }
  }

  @media (max-width: 395px) {
    padding-top: 2rem;

    & .right-col {
      padding-left: 0;
    }
  }

  @media (max-width: 375px) {
    padding-top: 0.35rem;

    & .right-col {
      padding-left: 0;
    }
  }

  @media (max-width: 360px) {
    padding-top: 1rem;
    padding-left: 2.7rem;

    & .right-col {
      padding-left: 0;
    }
  }
`;

const Showcase = () => {
  const [videoEnded, setVideoEnded] = useState<boolean>(false);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [showPlaylist, setShowPlaylist] = useState<boolean>(false);
  const [showButton2, setShowButton2] = useState<boolean>(false);
  const [showSpotifyButton, setShowSpotifyButton] = useState<boolean>(false);
  const [count, setCount] = useState(0);
  const [token, setToken] = useState("");
  const videoRef: any = useRef(null);
  let cnt = 0;
  let trackList = new Array("spotify:track:7HAPnbrRswVgXYSGNzO1hF","spotify:track:70Kv6vWsCZeQryTBebFvbX");
  let popupLogin: any = useRef(null);

  // this handles if video is ended
  useEffect(() => {
    const video = videoRef.current as HTMLVideoElement;

    // if (videoEnded) {
    //   setShowButton(true);
    // }
    if (!showButton && !videoEnded) {
      // console.log(video);
      video.currentTime = 0.1;
      setTimeout(function() {
        setShowButton(true);
      }, 500);
    }

  }, [videoEnded]);

  useEffect(() => {
    let tok: any = window.localStorage.getItem("token");
    if (tok)
      setToken(tok);
  }, [token]);

  useEffect(() => {
    let plTimer = setInterval(function() {
      if (window.localStorage.getItem("playlistCreated") !== null) {
        let show: any = window.localStorage.getItem("playlistCreated");
        setShowButton2((show === 'true' ? true : false));
        clearInterval(plTimer);
      }
    }, 1000);
  }, []);

  // const handleLoadedMetadata = () => {
  //   const video = videoRef.current as HTMLVideoElement;;
  //   if (!video) return;
  //   console.log(`The video is ${video.duration} seconds long.`);
  // };

  const timeUpdate = (event: any) => {
    if (!videoEnded && event.target.currentTime > 3) {
      setVideoEnded(true);
      setShowPlaylist(true);
    }
  }

  const shufflePlaylist = data.sort((a, b) => 0.5 - Math.random());

  let playlistItems = shufflePlaylist.map((item: { title: string; artist: string; trackId: string }, idx: number) => 
    <div
      className="h-[2.3rem] lg:h-[4.5rem] 3xl:h-24 pr-6"
    >
      <Typewriter
        options={{
          cursor: '',
          delay: 100,
          skipAddStyles: false
        }}
        onInit={(typewriter)=> {
          typewriter
            .typeString('<h1>'+item.title+'</h1>')
            .callFunction(() => {
            })
            .pauseFor(1000)
            .typeString('<h3>'+item.artist+'</h3>')
            .callFunction(() => {
              if (item.trackId.length > 0)
                trackList.push("spotify:track:" + item.trackId);
              
              let _tracks = {"uris": trackList};
              
              cnt = cnt + 1;
              if (cnt > 9 && !showSpotifyButton) {
                setShowSpotifyButton(true);
                window.localStorage.setItem("tracks", JSON.stringify(_tracks));
              }
            })
            .start();
        }
      }
      />
    </div>
  );
  
  return (
    <div
      id={RoutePattern.HOME}
      className="relative flex flex-col justify-center items-center h-screen w-full overflow-hidden py-10"
    >
      <div className="z-30 flex flex-col justify-center items-center h-screen w-full">
        <VideoContainer
          className="h-auto lg:h-full w-full align-center"
        >
          <VideoPlayer
            id="video_player"
            ref={videoRef}
            preload="metadata"
            playsInline
            // autoPlay
            muted
            src={(!isMobile ? VIDEO1 : VIDEO2)}
            // onEnded={() => setVideoEnded(true)}
            onTimeUpdate={(event: any) => {timeUpdate(event)} }
            className="h-screen w-full object-cover md:object-contain block object-center lg:scale-[1.167] 3xl:scale-[1.125] lg:-mb-16 3xl:-mb-14"
            // poster={(!isMobile ? IMG1 : IMG2)}
          ></VideoPlayer>
        </VideoContainer>
      </div>

      {showButton ? (
        <>
          <div
            className="z-40 absolute flex mr-[-3%] mb-[25%] md:mr-[1%] md:mb-[7%]"
          >
            <button
              className="bg-black text-[#fcb40a] hover:bg-[#fcb40a] hover:text-black px-[0.45rem] md:px-5 pt-1 pb-0 md:pt-1 text-sm md:text-2xl border-1 md:border-2 border-black rounded-lg md:rounded-xl"
              onClick={(event) => {
                setShowButton(false);
                const video = videoRef.current as HTMLVideoElement;
                video.play();
              }}
            >
              GENERATE PLAYLIST
            </button>
          </div>
        </>
      ) : null}

      {showSpotifyButton ? (
        <>
          <div
            className="z-50 absolute flex bottom-1/4 lg:bottom-3 3xl:bottom-10"
          >
            <button
              className="bg-black text-[#fcb40a] hover:bg-[#fcb40a] hover:text-black px-5 pt-2 pb-1 md:pt-1 text-2xl border-2 border-black rounded-xl"
              onClick={(event) => {
                setShowSpotifyButton(false);
                popupLogin = window.open(
                  SPOTIFY_AUTH_ENDPOINT + "?client_id=" + SPOTIFY_CLIENT_ID + "&redirect_uri=" + SPOTIFY_REDIRECT_URI + "&response_type=" + SPOTIFY_RESPONSE_TYPE + "&scope=streaming+user-read-email+user-top-read+user-read-private+user-modify-playback-state+playlist-modify-public+playlist-modify-private&show_dialog=true&let_me_in=yes",
                  'Login with Spotify',
                  'width=600,height=800'
                )
              }}
            >
              CREATE SPOTIFY PLAYLIST
            </button>
          </div>
        </>
      ) : null}

      {showButton2 ? (
        <>
          <div
            className="z-50 absolute flex bottom-1/4 lg:bottom-3 3xl:bottom-10"
          >
            <button
              className="bg-black text-[#fcb40a] hover:bg-[#fcb40a] hover:text-black px-5 pt-2 pb-1 md:pt-1 text-2xl border-2 border-black rounded-xl"
              onClick={(event) => {
                let thisUrl: any = window.localStorage.getItem("playlistUrl");
                // window.location.href = thisUrl;
                window.open(thisUrl);
              }}
            >
              VIEW SPOTIFY PLAYLIST
            </button>
          </div>
        </>
      ) : null}

      {showPlaylist ? (
        <>
          <PlaylistContainer
            className="z-30 absolute flex text-black w-full md:w-2/5 h-2/6 pl-12 md:pl-0 md:-mt-14"
          >
            <div
              className="flex flex-col w-[10.7rem] lg:w-[22rem] 3xl:w-[27rem] left-col"
            >
              <div
                className="h-[2.3rem] lg:h-[4.5rem] 3xl:h-24 pr-2"
              >
                <Typewriter
                  options={{
                    cursor: '',
                    delay: 100
                  }}
                  onInit={(typewriter)=> {
                    typewriter
                      .typeString('<h1>"SUMMER"</h1>')
                      .callFunction(() => {
                        setCount(count => count + 1);
                      })
                      .pauseFor(1000)
                      .typeString('<h3>KENYA VAUN</h3>')
                      .callFunction(() => {
                      })
                      .start();
                  }
                }
                />
              </div>
              <div
                className="h-[2.3rem] lg:h-[4.5rem] 3xl:h-24 pr-2"
              >
                <Typewriter
                  options={{
                    cursor: '',
                    delay: 100
                  }}
                  onInit={(typewriter)=> {
                    typewriter
                      .typeString('<h1>"OVERRATED"</h1>')
                      .callFunction(() => {
                        setCount(count => count + 1);
                      })
                      .pauseFor(1000)
                      .typeString('<h3>KENYA VAUN</h3>')
                      .callFunction(() => {
                      })
                      .start();
                  }
                }
                />
              </div>
              {playlistItems[0]}
              {playlistItems[1]}
              {playlistItems[2]}
              {playlistItems[3]}
            </div>
            <div
              className="flex flex-col w-[10.7rem] lg:w-[22rem] 3xl:w-[27rem] lg:pl-10 3xl:pl-12 right-col"
            >
              {playlistItems[4]}
              {playlistItems[5]}
              {playlistItems[6]}
              {playlistItems[7]}
              {playlistItems[8]}
              {playlistItems[9]}
            </div>
          </PlaylistContainer>
        </>
      ) : null}
    </div>
  );
};

export default Showcase;
