const hash = window?.location?.hash;
let tok: any = window.localStorage.getItem("token");

if (hash && hash) {
    tok = hash!.substring(1).split("&").find(elem => elem.startsWith("access_token"))?.split("=")[1];
    window.location.hash = "";
    window.localStorage.setItem("token", tok);

    window.opener.spotifyCallback(tok);

    window.close();
}

export default function Callback() {
  return (
    <></>
  );
}
