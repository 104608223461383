export const data = [
  // {
  //   title: '"OVERRATED"',
  //   artist: "KENYA VAUN",
  //   trackId: "70Kv6vWsCZeQryTBebFvbX"
  // },
  {
    title: '"LOVE NO LIMIT"',
    artist: "MARY J. BLIGE",
    trackId: "7gEGhEb1VcCeC6lJWnjI9Z"
  },
  {
    title: '"FAMILY AFFAIR"',
    artist: "MARY J. BLIGE",
    trackId: "3aw9iWUQ3VrPQltgwvN9Xu"
  },
  {
    title: '"SUPERMODEL"',
    artist: "SZA",
    trackId: "5wTVNpi5WDByxBgKgUE6MU"
  },
  {
    title: '"SHIRT"',
    artist: "SZA",
    trackId: "2wSTnntOPRi7aQneobFtU4"
  },
  {
    title: '"GOOD DAYS"',
    artist: "SZA",
    trackId: "4PMqSO5qyjpvzhlLI5GnID"
  },
  {
    title: '"MILLION"',
    artist: "TINK",
    trackId: "1Ktsil4bC4hYRWlqMG4qK3"
  },
  {
    title: '"MAKE ME STAY"',
    artist: "SONDER",
    trackId: "3REQkt60mafExNVnJehMdL"
  },
  {
    title: '"DOWN BAD"',
    artist: "DESTIN CONRAD",
    trackId: "48pi6NxVvt4npqDnLBi0iK"
  },
  {
    title: '"BILLS"',
    artist: "DESTIN CONRAD",
    trackId: "0H6CZCG2F1N3XZWNZAVqE7"
  },
  {
    title: '"AMBRE\'S<br>&nbsp;&nbsp;INTERLUDE"',
    artist: "DESTIN CONRAD",
    trackId: "3WwvdFw13kO3JF26XCKsGz"
  },
  {
    title: '"THERE\'S HOPE"',
    artist: "INDIA.ARIE",
    trackId: "4BnfCXc53Qovct84nGzoFq"
  },
  {
    title: '"WHEN I SEE U"',
    artist: "FANTASIA",
    trackId: "4iuNZTcvT9diFySSzVsnVS"
  },
  {
    title: '"GREEN EYES"',
    artist: "ERYKAH BADU",
    trackId: "6qvtiA4wQQliK6K7oRdovk"
  },
  {
    title: '"VIDEO"',
    artist: "INDIA.ARIE",
    trackId: "4oiAlcisfimXQc1wyAtX9a"
  },
  {
    title: '"EVERYTHING IS<br>&nbsp;&nbsp;EVERYTHING"',
    artist: "MS. LAURYN HILL",
    trackId: "7t86fVeDAd63ThaR0ZkxXS"
  },
];
